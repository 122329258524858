s<template>
  <!-- 退货原因管理 -->
  <div class="container">
    <el-card class="head">
      <div>
        <div class="OperButton">
          <router-link to="/setting/order/reason/add">
            <div class="addBtn">
              <img src="../../../assets/images/add.png" style="margin-right: 5px;width:13px">添加
            </div>
          </router-link>
        </div>
      </div>
    </el-card>
    <div style="height: 10px"></div>
    <div class="body">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;font-family: PingFangRoutine;"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column prop="id" label="ID" align="center">
          </el-table-column>
          <el-table-column prop="reason" label="退货原因" align="center">
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
          </el-table-column>
          <el-table-column prop="type" label="类型" align="center">
            <template #default="scope">
              {{
                scope.row.type === 1
                  ? "取消"
                  : scope.row.type === 2
                  ? "退货"
                  : "退款"
              }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="reasonEdit(scope.row)">
                 <img style="width: 18px;height: 18px;" src="../../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="reasonDel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import { reasonList, reasonDel } from "@/api/reason";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      currPage: "",
      datatime: [],
      tableData: [],
      source: [],
      level: [],
      total: null,
      setting: [],
    };
  },
  activated() {
    this.list();
  },
  methods: {
    //退货原因列表
    list() {
      reasonList({ currPage: this.currPage }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //删除
    async reasonDel(id) {
      if (!(await this._box())) return;
      reasonDel({ id: id }).then((res) => {
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage = this.currPage > totalPage ? totalPage : this.currPage;
        this.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },
    //编辑
    reasonEdit(val) {
      this.$router.push({
        path: "/setting/order/reason/edit",
        query: {
          id: val.id,
          reason: val.reason,
          type: val.type,
          remark: val.remark,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/common.less";
/deep/ .head .el-card__body{
    padding-left: 0px;
  }
  .dataOper{
    padding: 20px;
    padding-left: 0px;
  }
</style>
